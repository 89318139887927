import styled from 'styled-components/macro';
import { Box, Heading, Button, Paragraph } from '~styled';

const Hero = styled(Box)`
  padding-top: 0;
  padding-top: 120px;
  padding-bottom: 60px;
  position: relative;
`;

Hero.Content = styled(Box)`
  @media (min-width: 992px) {
    padding-bottom: 100px;
  }

  @media (min-width: 1600px) {
    padding-bottom: 0;
  }
`;
Hero.SubTitle = styled(Heading)`
  font-size: 18px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 25px;
  margin-bottom: 15px;
`;
Hero.Title = styled(Heading)`
  font-size: 38px;
  font-weight: 500;
  letter-spacing: -0.5px;
  line-height: 1.2068;
  margin-bottom: 21px;
  margin-bottom: 30px;

  @media (min-width: 768px) {
    font-size: 48px;
  }

  @media (min-width: 992px) {
    font-size: 60px;
    font-weight: 500;
    letter-spacing: normal;
    line-height: 72px;
  }
`;
Hero.Text = styled(Paragraph)`
  font-size: 18px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 32px;
  margin-bottom: 10px;
  opacity: 0.7;
`;

Hero.ButtonGroup = styled(Button)`
  padding-top: 28px;
  margin-bottom: -20px;

  .btn {
    margin-bottom: 20px;
  }
`;
Hero.Button = styled(Button)`
  min-width: 130px;
  height: 50px;
  border-radius: 500px;
  font-size: 14px;
  @media (min-width: 480px) {
    min-width: 180px;
    height: 56px;
    font-size: 15px;
  }

  &.🌱 {
    margin-right: 18px;
    margin-bottom: 20px;
  }

  &.btn-outline {
    color: #136de8;
    background-color: transparent;
    border-color: #136de8;
    &:hover {
      color: #136de8;
      background-color: white;
      border-color: #136de8;
    }
  }
  &.btn-green {
    color: #fff;
    background-color: #136de8;
    border-color: #136de8;
    &:hover {
      color: #fff;
      background-color: #136de8;
      border-color: #136de8;
    }
  }
`;
Hero.ImageGroup = styled(Box)`
  display: flex;
  align-items: flex-end;
`;

Hero.ImageOne = styled(Box)`
  position: absolute;
  width: 500px;
  height: 320px;
  right: -100px;
  top: 0;
  bottom: 0;
  margin: auto;
`;

Hero.Box = styled(Box)``;

export default Hero;
