import React, { useState } from 'react';
import { Col, Container, Modal, Row, Button } from 'react-bootstrap';
import Promo from './style';
import SectionTitle from './Components/SectionTitle';
import { StaticImage as Img } from 'gatsby-plugin-image';
import { Link } from '~components';
import HubspotForm from 'react-hubspot-form';

export default function CtaSection() {
  const [show, toggleModal] = useState();

  const handleClose = () => {
    toggleModal(false);
  };

  return (
    <Promo>
      <Container>
        <Row className="justify-content-center justify-content-xl-end">
          <Col
            xs="12"
            className="col-xxl-6 col-xl-5 col-lg-5 col-md-7 col-xs-9"
          >
            <Promo.Image>
              <Img
                src="../../../assets/image/home-app/promo-l8-img.png"
                alt="promo"
                layout="constrained"
                placeholder="blurred"
              />
            </Promo.Image>
          </Col>
          <Col className="col-xs-6">
            <Promo.Content>
              <Promo.Box>
                <SectionTitle
                  title="Sign up and start connecting directly with your audience"
                  subTitleProps={{ mb: '30px', className: 'black' }}
                  titleProps={{ mb: '30px' }}
                />
              </Promo.Box>
              <Promo.ButtonGroup mt="25px">
                <Promo.Button
                  className="btn-1"
                  onClick={() => {
                    toggleModal(true);
                  }}
                >
                  Learn More
                </Promo.Button>
                <Promo.Button
                  target="_blank"
                  className="btn-2"
                  as={Link}
                  to="https://app.actovos.com/sign-up"
                >
                  Sign Up
                </Promo.Button>
              </Promo.ButtonGroup>
            </Promo.Content>
          </Col>
        </Row>
      </Container>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Learn More</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <HubspotForm
            portalId="7603016"
            formId="91edcf12-b0cd-4681-81dd-90cba482312f"
            onSubmit={() => console.log('Submit!')}
            onReady={form => console.log('Form ready!')}
            loading={<div>Loading...</div>}
          />
        </Modal.Body>
      </Modal>
    </Promo>
  );
}
