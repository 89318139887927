import styled from 'styled-components/macro';
import { Box, Heading, Button, Paragraph } from '~styled';

const Promo = styled(Box)`
  background-image: linear-gradient(
    300deg,
    #136de8 0%,
    #1ba5ff 33%,
    #0f7ec9 53%,
    #1080cb 53%,
    #5ebfff 100%
  );
  position: relative;
  padding-top: 60px;
  padding-bottom: 60px;
  background-repeat: no-repeat;
  background-size: cover;

  @media (min-width: 768px) {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  @media (min-width: 992px) {
    padding-top: 130px;
    padding-bottom: 130px;
  }
`;

Promo.Image = styled(Box)`
  @media (min-width: 1200px) {
    position: absolute;
    bottom: -7px;
    left: 0;
    width: 100%;
  }
  @media (min-width: 1600px) {
    left: 10%;
  }
`;
Promo.Content = styled(Box)`
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.87) 0%,
    rgba(255, 255, 255, 0.62) 29%,
    rgba(255, 255, 255, 0.2) 100%
  );
  padding-top: 65px;
  padding-bottom: 60px;
  padding-left: 30px;
  padding-right: 30px;
  border-radius: 25px;
  @media (min-width: 768px) {
    padding-left: 80px;
    padding-right: 50px;
  }
  @media (min-width: 576px) {
    padding-left: 60px;
    padding-right: 60px;
  }
`;

Promo.Box = styled(Box)``;

Promo.ButtonGroup = styled(Box)`
  margin-right: -17.0656px;
  display: flex;
  flex-wrap: wrap;

  .btn-1 {
    background: white;
    border: none;
    color: #136de8;
  }

  .btn-2 {
    background: #136de8;
    border: none;
    color: white;
  }
`;

Promo.Button = styled(Button)`
  margin-right: 17.0656px;
  border-radius: 500px;
  color: #fff;
  min-width: 140px;
  height: 56px;
  margin-top: 15px;
  @media (min-width: 1200px) {
    min-width: 165px;
    height: 60px;
  }
  &.btn-1 {
    box-shadow: 4px 4px 56px rgb(255 87 34 / 0%);
    &:hover {
      box-shadow: 4px 4px 56px rgb(80 52 252 / 30%);
    }
  }
  &.btn-2 {
    box-shadow: 4px 4px 56px rgb(80 52 252 / 0%);
    &:hover {
      box-shadow: 4px 4px 56px rgb(80 52 252 / 30%);
    }
  }
`;

export default Promo;
