import React from 'react';
import { PageWrapper } from '~components/Core';
import HeroSection from '~sections/app/Hero';
import HeaderButton from '~sections/app/Header';
import FeatureSection from '~sections/project/Features';
import ServicesSection from '~sections/it/Services';
import AboutSection from '~sections/digital/About';
import CaseStudy from '~sections/digital/ContentOne';
import Contact from '~sections/contact/ContactOne/ContactSection';
import PromoSection from '~sections/app/Promo';
import FooterFour from '~sections/app/FooterFour';
import TestimonialSection from '~sections/agency/Testimonial';
import { Link } from '~components';

const header = {
  headerClasses:
    'site-header site-header--menu-end light-header site-header--sticky',
  containerFluid: false,
  buttonBlock: (
    <HeaderButton
      as={Link}
      className="d-none d-sm-flex"
      children="App Login"
      target="_blank"
      to="https://app.actovos.com"
    />
  ),
};

export default function HomeApp() {
  return (
    <PageWrapper headerConfig={header}>
      <HeroSection />
      <FeatureSection />
      <ServicesSection />
      <AboutSection />
      <CaseStudy />
      <TestimonialSection />
      <PromoSection />
      <Contact />
      <FooterFour />
    </PageWrapper>
  );
}
