import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import HubspotForm from 'react-hubspot-form';
import { BrowserFrame, VoyageLogo } from '@voyageapps/shared-ui';
import theme from '@voyageapps/shared-ui/src/theme';
import { Col, Container, Row, Modal } from 'react-bootstrap';
import { Link } from '~components';
import Hero from './style';
import { Provider, Text, Flex } from '@actovos-consulting-group/ui-core';

const Frame = styled.iframe`
  margin-bottom: -5px;
  height: 600px;
`;

const AppFrame = styled(BrowserFrame)`
  max-width: 1000px;
  margin: 50px auto;
  box-shadow: 0px 0px 143px 2px #1f559c8f;

  ${BrowserFrame.Styled.AddressBar} {
    background-color: #136de8;
  }
`;

export default function HeroSection() {
  const [show, toggleModal] = useState();

  const handleClose = () => {
    toggleModal(false);
  };

  return (
    <Provider theme={theme}>
      <Hero backgroundColor="rgb(240, 245, 255)">
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col className="col-xs-12 mt-5 mb-5 text-center">
              <Hero.Content>
                <Hero.Title as="h1">Connect with your audience</Hero.Title>
                <Hero.Text fontColor="rgba(38, 39, 41, 0.7)">
                  Use our omni-channel software to connect with your audience
                  immediately.
                </Hero.Text>
              </Hero.Content>
              <Hero.ButtonGroup>
                <Hero.Button
                  className="btn-outline 🌱"
                  onClick={() => {
                    toggleModal(true);
                  }}
                >
                  Learn More
                </Hero.Button>
                <Hero.Button
                  className="btn-green"
                  as={Link}
                  target="_blank"
                  to="https://app.actovos.com/sign-up"
                >
                  Try it Now
                </Hero.Button>
              </Hero.ButtonGroup>
            </Col>
            <Col xs="12" className="col-xs-12">
              <AppFrame url="https://app.actovos.dev/demo">
                <Frame
                  width="100%"
                  scrolling="no"
                  title="ShipFinder"
                  src="https://app.actovos.dev/demo"
                  frameBorder="no"
                />
                <Flex p={20} bg="#051524">
                  <Link
                    target="_blank"
                    style={{ display: 'flex' }}
                    to="https://voyageapp.io/?utm_source=actovos_brochure&utm_medium=web&utm_campaign=deployed_with_voyage"
                  >
                    <Text color="white" mr={2} mt="2px" fontSize={14}>
                      Deployed with
                    </Text>
                    <VoyageLogo width={80} color="white" />
                  </Link>
                </Flex>
              </AppFrame>
            </Col>
          </Row>
        </Container>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Learn More</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <HubspotForm
              portalId="7603016"
              formId="91edcf12-b0cd-4681-81dd-90cba482312f"
              onSubmit={() => console.log('Submit!')}
              onReady={form => console.log('Form ready!')}
              loading={<div>Loading...</div>}
            />
          </Modal.Body>
        </Modal>
      </Hero>
    </Provider>
  );
}
